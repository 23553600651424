





























































































































































































































































































import Vue from 'vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import * as api from '@/api/project';
import { setTableHeaderWidth, subtract } from '@/utils';
import AdjustmentSuccess from '@/views/projects/detail/adjustmentSuccess/index.vue';
import PricePopover from '@/views/projects/detail/artificialAdjustment/pricePopover.vue';
import { BaseResponse } from '@/interface';

const analysisData = [
  {
    name: '工程项目合计',
    value: 0,
    code: 'constructCount',
    icon: 'icon-gongchengxiangmuheji',
  },
  {
    name: '目标投标价',
    value: 0,
    code: 'singleCount',
    icon: 'icon-mubiaotoubiaojia',
  },
  {
    name: '差额',
    value: 0,
    code: 'unitCount',
    icon: 'icon-chae',
  },
  {
    name: '调整后预估价格',
    value: 0,
    code: 'branchCount',
    icon: 'icon-tiaozhenghouxiangmuheji',
  },
];

const columns = [
  {
    title: '项目名称',
    dataIndex: 'name',
    key: 'name',
    align: 'left',
    width: 300,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '调整前合计(元)',
    dataIndex: 'beforeTotal',
    key: 'beforeTotal',
    align: 'center',
    scopedSlots: { customRender: 'beforeTotal' },
  },
  {
    title: '调整后合计(元)',
    dataIndex: 'afterTotal',
    key: 'afterTotal',
    align: 'center',
    scopedSlots: { customRender: 'afterTotal' },
  },
  {
    // title: '人工费(元)',
    dataIndex: 'rgf',
    key: 'rgf',
    align: 'center',
    slots: { title: 'rgfTitle' },
    scopedSlots: { customRender: 'rgf' },
    customCell: (record: dataItem) => {
      if (record.project !== 'construct') {
        return { style: { color: '#B5B5B5' } };
      } else if (record.isEdit) {
        return { style: { color: '#1E90FF' } };
      }
    },
  },
  {
    // title: '材料费(元)',
    dataIndex: 'clf',
    key: 'clf',
    align: 'center',
    slots: { title: 'clfTitle' },
    scopedSlots: { customRender: 'clf' },
    customCell: (record: dataItem) => {
      if (record.project !== 'construct') {
        return { style: { color: '#B5B5B5' } };
      } else if (record.isEdit) {
        return { style: { color: '#1E90FF' } };
      }
    },
  },
  // {
  //   title: '锁定材料费(元)',
  //   dataIndex: 'lockClf',
  //   key: 'lockClf',
  //   align: 'center',
  // },
  {
    // title: '机械费(元)',
    dataIndex: 'jxf',
    key: 'jxf',
    align: 'center',
    slots: { title: 'jxfTitle' },
    scopedSlots: { customRender: 'jxf' },
    customCell: (record: dataItem) => {
      if (record.project !== 'construct') {
        return { style: { color: '#B5B5B5' } };
      } else if (record.isEdit) {
        return { style: { color: '#1E90FF' } };
      }
    },
  },
  {
    title: '管理费及利润(元)',
    dataIndex: 'glf',
    key: 'glf',
    align: 'center',
    scopedSlots: { customRender: 'glf' },
    customCell: (record: dataItem) => {
      if (record.project !== 'construct') {
        return { style: { color: '#B5B5B5' } };
      } else if (record.isEdit) {
        return { style: { color: '#1E90FF' } };
      }
    },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    width: 160,
  },
];

interface dataItem {
  id: string;
  name: string;
  beforeTotal: string;
  afterTotal: string;
  rgf: string;
  clf: string;
  jxf: string;
  glf: string;
  isEdit?: boolean;
  isLoading?: boolean;
  children: dataItem[];
  code?: number;
  project: string;
}

interface childrenItem {
  rgfPercent: string;
  clfPercent: string;
  jxfPercent: string;
  glfPercent: string;
  name: string;
  id: string;
  rgf: string;
  clf: string;
  jxf: string;
  glf: string;
  isLoading: boolean;
}

export default Vue.extend({
  name: 'artificialAdjustment',
  components: { AdjustmentSuccess, PricePopover },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      analysisData,
      data: [] as dataItem[],
      columns,
      expandedRowKeys: [] as string[],
      editingKey: '',
      saveObj: {
        rgfPercent: '',
        clfPercent: '',
        jxfPercent: '',
        glfPercent: '',
        constructId: '',
        // singleId: '',
        // children: [] as childrenItem[],
        // code: 0,
        // tag: 0, // 0不调整其他单位工程中 相同清单项  1调整
      },
      successVisible: false,
      loading: false, // 提交时加载
      originalData: [], // 原始数据 用于取消时还原数据
      priceLoading: false, // 价格计算时加载
      isEditData: false, // 是否编辑过数据
      listLoading: true, // 获取列表数据时加载
      switchStatus: false, // 开关状态
      closeVisible: false,
    };
  },
  computed: {
    ...mapGetters(['projectBidSequenceNbr']),
    changePriceTotal() {
      let total = 0;
      if (this.saveObj.constructId) {
        this.data.forEach((item) => {
          total +=
            item.afterTotal === item.beforeTotal
              ? Number(Number(item.beforeTotal).toFixed(2))
              : Number(Number(item.afterTotal).toFixed(2));
        });
      }
      return total ? Number(total.toFixed(2)) : '--';
    },
    diffPrice() {
      let price = '';
      if (this.changePriceTotal > 0) {
        price = subtract(
          Number(this.changePriceTotal),
          this.analysisData[1].value
        );
      } else {
        price = subtract(
          this.analysisData[0].value,
          this.analysisData[1].value
        );
      }
      return Number(price) > 0 ? '+' + price : price;
    },
  },
  watch: {
    visible: function (val) {
      if (val) {
        this.expandedRowKeys = [];
        this.getPriceGroup();
        this.treeBySequenceNbr();
        window.addEventListener('resize', this.getTableHeaderWidth);
      } else {
        window.removeEventListener('resize', this.getTableHeaderWidth, false);
      }
    },
  },
  methods: {
    ...mapMutations(['SET_IS_THERE_REPLACE']),
    ...mapActions(['getGenerateListStatus']),
    getTableHeaderWidth() {
      setTableHeaderWidth('.adjustment-list');
    },
    getPriceGroup() {
      api
        .coccMtdsResource_1_constructProject_priceBySequenceNbr(
          this.projectBidSequenceNbr
        )
        .then((res: BaseResponse) => {
          if (res.status === 200) {
            this.analysisData[0].value = res.result.price;
            this.analysisData[1].value = res.result.targetBidPrice;
          }
        });
    },
    close() {
      if (this.priceLoading) {
        return;
      }
      console.log(this.isEditData);
      if (!this.isEditData) {
        this.closeHandle();
        return;
      }
      this.closeVisible = true;
      // this.$emit('update:visible', false);
    },
    closeHandle() {
      this.$emit('update:visible', false);
      this.expandedRowKeys = [];
      this.saveObj.constructId = '';
      this.isEditData = false;
      this.listLoading = true;
      this.closeVisible = false;
    },
    /**
     * 点击 调整价格 按钮
     * @param id
     */
    handleAdjustPrice(id: string) {
      const newData = [...this.data];
      const target = newData.filter(
        (item: dataItem) => id === item.id
      )[0] as dataItem;
      if (target) {
        target.isEdit = true;
        this.data = newData;
        this.originalData = JSON.parse(JSON.stringify(this.data));
      }
    },
    updateData(editObj) {
      console.log('editObj', editObj);
      // const { rgfPercent, clfPercent, jxfPercent, glfPercent } =
      //   editObj.currentInfo;
      this.saveObj[`${editObj.editKey}` + 'Percent'] =
        editObj.currentInfo[`${editObj.editKey}` + 'Percent'];
      // this.saveObj = {
      //   ...this.saveObj,
      //   rgfPercent,
      //   clfPercent,
      //   jxfPercent,
      //   glfPercent,
      // };
      this.saveObj.constructId = this.projectBidSequenceNbr;
      console.log('111111111111', this.saveObj);
      this.isEditData = editObj.isEditData;
    },
    /**
     * 保存
     * @param record
     */
    saveSinglePrice(record: dataItem) {
      const newData = [...this.data];
      const target = newData.filter(
        (item: dataItem) => record.id === item.id
      )[0] as dataItem;
      if (this.saveObj.constructId) {
        // const { rgfPercent, clfPercent, jxfPercent, glfPercent } = this.saveObj;
        // this.saveObj = {
        //   ...this.saveObj,
        //   rgfPercent,
        //   clfPercent,
        //   jxfPercent,
        //   glfPercent,
        //   // singleId: record.id,
        //   // code: record.code ? record.code : 0,
        //   // tag: this.switchStatus ? 1 : 0,
        // };
        this.priceLoading = true;
        target.isLoading = true;
        target.children.filter((rItem: dataItem) => {
          rItem.isLoading = true;
          rItem.children.filter((item: dataItem) => {
            item.isLoading = true;
          });
        });
        this.data = newData;
        api
          .coccMtdsResource_1_constructProject_singlePrice(this.saveObj)
          .then((res) => {
            if (res.status === 200 && res.result) {
              this.$message.success('保存成功');
              this.closeEdit(record.id, true);
              this.treeCacheBySequenceNbr(record.id);
            } else {
              this.$message.error('保存失败');
            }
          })
          .catch(() => {
            this.priceLoading = false;
            this.$message.error('保存失败');
          });
      } else {
        this.closeEdit(record.id);
      }
    },
    /**
     * 取消
     */
    closeEdit(id: string, flag = false) {
      if (!flag) this.saveObj.constructId = '';
      this.saveObj = {
        ...this.saveObj,
        rgfPercent: '',
        clfPercent: '',
        jxfPercent: '',
        glfPercent: '',
      };
      const newData = [...this.originalData];
      const target = newData.filter(
        (item: dataItem) => id === item.id
      )[0] as dataItem;
      if (target) {
        target.isEdit = false;
        this.data = newData;
      }
      this.isEditData = false;
    },
    /**
     * 点击树形结构
     * @param expanded
     * @param record
     */
    expand(expanded, record) {
      if (expanded) {
        this.expandedRowKeys.push(record.id);
      }
      this.originalData = JSON.parse(JSON.stringify(this.data));
    },
    clickExpand(record) {
      if (this.expandedRowKeys.indexOf(record.id) === -1) {
        this.expandedRowKeys.push(record.id);
      } else {
        this.expandedRowKeys.splice(this.expandedRowKeys.indexOf(record.id), 1);
      }
      console.log('record.id', this.expandedRowKeys);
      this.originalData = JSON.parse(JSON.stringify(this.data));
    },
    /**
     * 获取表格弹框数据
     */
    treeBySequenceNbr() {
      api
        .coccMtdsResource_1_constructProject_treeBySequenceNbr(
          this.projectBidSequenceNbr
        )
        .then((res) => {
          if (res.status === 200) {
            this.listLoading = false;
            this.data = [res.result];
            console.log('this.data', this.data);
            this.getTableHeaderWidth();
          }
        });
    },
    treeCacheBySequenceNbr(id) {
      api
        .coccMtdsResource_1_constructProject_treeCacheBySequenceNbr(
          this.projectBidSequenceNbr
        )
        .then((res) => {
          if (res.result && res.status === 200) {
            this.priceLoading = false;
            res.result.isLoading = false;
            res.result.children.forEach((singleItem) => {
              singleItem.isLoading = false;
              singleItem.children.forEach((item) => {
                item.isLoading = false;
              });
            });
            this.data = [res.result];
            // let newData;
            // newData = res.result.children.map((item: dataItem) => {
            //   let target = res.result.filter(
            //     (item: dataItem) => id === item.id
            //   )[0] as dataItem;
            //   if (item.id == id) {
            //     this.expandedRowKeys = [];
            //     if (this.expandedRowKeys.indexOf(target.id) === -1) {
            //       this.expandedRowKeys.push(target.id);
            //     }
            //     target.isLoading = false;
            //     target.code = 1;
            //     target.children.forEach((item) => {
            //       item.isLoading = false;
            //     });
            //     return (item = target);
            //   } else {
            //     item.isLoading = false;
            //     item.children.forEach((child) => {
            //       child.isLoading = false;
            //     });
            //     return item;
            //   }
            // });
            // for (let i = 0; i < this.data[0].children.length; i++) {
            //   for (let j = 0; j < res.result.children.length; j++) {
            //     if (this.data[0].children[i].id === res.result.children[j].id) {
            //       this.data[0].children[i] = res.result.children[j];
            //     }
            //   }
            // }
            // this.data = newData;
            console.log('0000000', this.data);
          } else {
            this.priceLoading = false;
          }
        });
    },
    /**
     * 提交
     */
    submit() {
      console.log('submit', this.data);
      let isEdit = this.data.every((item: dataItem) => {
        console.log('item', item.isEdit);
        return !item.isEdit;
      });
      if (isEdit) {
        if (!this.saveObj.constructId)
          return this.$message.warning('目前没有调整项哟~');
        this.loading = true;
        api
          .coccMtdsResource_1_constructProject_singleResult({
            constructId: this.projectBidSequenceNbr,
          })
          .then((res) => {
            console.log('res', res);
            if (res.status === 200 && res.result) {
              this.SET_IS_THERE_REPLACE(false);
              this.getGenerateListStatus();
              this.loading = false;
              this.$message.success('提交成功');
              this.$emit('update:visible', false);
              this.closeHandle();
              this.getPriceGroup();
              this.successVisible = true;
              // this.expandedRowKeys = [];
              // this.saveObj.children = [];
              // this.saveObj.constructId = '';
            } else {
              this.loading = false;
              this.$message.error('提交失败');
            }
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.$message.error('您还有工程处于编辑状态,请先保存信息~');
      }
    },
  },
});
