











































interface childrenItem {
  rgfPercent: string;
  clfPercent: string;
  jxfPercent: string;
  glfPercent: string;
  name: string;
  id: string;
  rgf: string;
  clf: string;
  jxf: string;
  glf: string;
  isLoading: boolean;
  beforeTotal: string;
  afterTotal: string;
}
import { accAdd, subtract } from '@/utils';
import Vue from 'vue';
export default Vue.extend({
  name: 'LPopover',
  props: {
    record: {
      type: Object,
      default: () => null,
    },
    editKey: {
      type: String,
    },
  },
  data() {
    return {
      adjustPercent: '',
      adjustType: 0,
      visible: false,
      isEditData: false, // 是否编辑过数据
    };
  },
  methods: {
    getPopupContainer() {
      return document.getElementById('ysf_body');
    },
    proving() {
      this.adjustPercent = (this.adjustPercent.match(/\d+(\.\d{0,2}|100)?/) || [
        '',
      ])[0];
    },
    enterChange() {
      this.visible = false;
      this.visibleChange();
    },
    handlePrice() {
      this.visible = true;
    },
    /**
     * popover框状态改变
     */
    visibleChange() {
      let obj = {
        rgfPercent: '',
        clfPercent: '',
        jxfPercent: '',
        glfPercent: '',
        isLoading: false,
      };
      // (this.children as childrenItem[]).forEach((item: childrenItem) => {
      //   if (item.id === obj.id) {
      //     obj = item;
      //   }
      // });
      let percent = (this.adjustType === 1 ? '' : '-') + this.adjustPercent;
      if (!this.visible) {
        if (this.adjustPercent && this.adjustType) {
          obj[`${this.editKey}` + 'Percent'] = percent;
          let currentPrice = this.record[this.editKey];
          // if (this.editKey === 'clf') {
          currentPrice = subtract(
            Number(Number(this.record[this.editKey]).toFixed(2)),
            Number(
              Number(this.record[`${this.editKey}` + 'Lock']).toFixed(2)
            ) || 0
          );
          // }
          this.record[this.editKey] = this.changePrice(currentPrice);
          this.updatePrice(this.record.children);
          obj[this.editKey] = this.record[this.editKey];
          this.isEditData = true;
          this.adjustType = 0;
          this.adjustPercent = '';
          console.log('obj', obj);
          let editObj = {
            isEditData: this.isEditData,
            currentInfo: obj,
            editKey: this.editKey,
          };
          if (Number(obj[this.editKey]) !== 0 && !this.visible) {
            this.$emit('updateData', editObj);
          }
        } else {
          this.$message.error('请选择调价方案或填写调价百分比');
        }
      }
    },
    updatePrice(data) {
      (data || []).forEach((item: any) => {
        item[this.editKey] = this.changePrice(item[this.editKey]);
        this.updatePrice(item.children);
      });
    },
    changePrice(price) {
      return this.adjustType === 1
        ? accAdd(
            Number(Number(price).toFixed(2)),
            Math.abs(Number(Number(price).toFixed(2))) *
              (Number(this.adjustPercent) / 100)
          ).toString()
        : subtract(
            Number(Number(price).toFixed(2)),
            Math.abs(Number(Number(price).toFixed(2))) *
              (Number(this.adjustPercent) / 100)
          );
    },
  },
});
